@import "src/scss/_common/common";

$gutter: 20px / 2;

.wrap {
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
  //margin: rem(30px) rem(-$gutter);
}

.list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 28px;

  & > .box {
    width: 175px;
    height: 180px;
    background-color: #fff;
    border-radius: 16px;
    padding: 0;
    overflow: hidden;
    border: 1px solid #D8DFEC;

    & > div > div > div > input ~ div {
      width: 100%;
      height: 180px;
      background-color: transparent;
      margin-right: 0;
    }

    img {
      height: 175px;
      width: auto;
    }
  }
}

.box {
  //max-width: rem(200px);
  padding: 0 rem($gutter) rem($gutter);
  border-radius: 16px;
  background: #FFF;
  &:first-child {
    margin-left: 177px;
  }
}

.ml_0:first-child {
  margin-left: 0;
}

.cover_image {
  border: 4px solid #5755d9;
}

.box_add {
  width: 175px;
  height: 180px;
  border-radius: 16px;
  border: 2px dashed #5755D1;
  background: #FFF;
  position: absolute;
}

.boxError {
  .drop {
    background: rgba(red, 0.35);
  }
}

.drop {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-top: 100%;

  > input {
    @extend %ex_input-hidden;
  }

  > label {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
  }

  > button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.inputDrop {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  opacity: 0;
}

.drop__block {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(10px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 1;

  & > p {
    color: #5755D1;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 0 8px;
  }
}

.hover__btn_group {
  right: 24px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div[class*="CopyComponent_wrap"] {
      display: flex;
      align-items: center;
      justify-content: center;

      & > div[class*="CopyComponent_img"] {
        margin: 0;

        & > div[class*="CopyComponent_copy"] {
          margin-left: 0;
          left: -114px;

          &:before {
            left: auto;
            right: -4px;
          }
        }
      }
    }
  }
}

button.btn_danger {
  border-color: #da3b2f;

  &:hover {
    border-color: #c2332a;
  }

  &:active,
  &:focus {
    border-color: #ff3225;
    background-color: #fbefee;
  }

  & > i {
    color: #da3b2f;
  }
}

.p_relative {
  position: relative;
}